import MotionNumber from 'motion-number';
import { isChainflipTokenOrChain, type ChainflipToken } from '@/shared/assets/tokens';
import { Callout } from '@/shared/components';
import { ChunkedSwapProgressBar } from '@/shared/components/ChunkedSwapProgressBar';
import LoadingSpinner from '@/shared/components/LoadingSpinner';
import { ArrowIcon } from '@/shared/icons/large';
import { formatTimeUntilExpiry, TokenAmount } from '@/shared/utils';
import { getSwapObject, getSwapScheduledAt } from 'packages/swap/utils/sdk';
import { type StatusResponse } from '../../integrations';

const getDcaInfo = (
  status: StatusResponse,
  swap: NonNullable<ReturnType<typeof getSwapObject>>,
) => {
  if (
    status.integration !== 'chainflip' ||
    !status.integrationData ||
    !status.integrationData.depositChannel
  ) {
    return undefined;
  }

  const {
    depositChannel: { dcaParams },
  } = status.integrationData;
  const { dca } = swap;

  if (!dca || !dcaParams) return undefined;

  const chunkStatus =
    !dca.currentChunk || dca.currentChunk.retryCount === 0
      ? ('active' as const)
      : ('inactive' as const);
  const totalChunks = dcaParams.numberOfChunks;
  const percent = (dca.executedChunks / totalChunks) * 100;

  return {
    executedChunks: dca.executedChunks,
    percent: Math.max(percent, 1), // ensure progress bar visible
    chunkStatus,
    totalChunks,
  };
};

type DcaInfo = NonNullable<ReturnType<typeof getDcaInfo>>;
type SwapFields = NonNullable<ReturnType<typeof getSwapObject>>;

const DcaStatus = ({
  info,
  swap,
  srcToken,
  destToken,
}: {
  info: DcaInfo;
  swap: SwapFields;
  srcToken: ChainflipToken;
  destToken: ChainflipToken;
}) => (
  <div className="flex flex-col gap-y-3">
    <ChunkedSwapProgressBar status={info.chunkStatus} progressPercent={info.percent} />
    <div className="flex items-center justify-between font-aeonikMedium text-20">
      <div className="flex items-center gap-x-1">
        <MotionNumber
          className="text-cf-light-4"
          value={TokenAmount.fromAsset(
            swap.remainingInputAmount,
            srcToken.chainflipId,
          ).toFixedDisplay()}
        />
        <span>{srcToken.symbol}</span>
      </div>
      <div className="flex items-center gap-x-1">
        <MotionNumber
          className="text-cf-light-4"
          value={TokenAmount.fromAsset(
            swap.swappedOutputAmount,
            destToken.chainflipId,
          ).toFixedDisplay()}
        />
        <span>{destToken.symbol}</span>
      </div>
    </div>
  </div>
);

export const SwappingStatus = ({ status }: { status: StatusResponse }) => {
  const swap = getSwapObject(status);
  const dcaInfo = swap && getDcaInfo(status, swap);
  const isRetrying = (swap?.regular?.retryCount ?? 0) > 0 || dcaInfo?.chunkStatus === 'inactive';
  const { srcToken, destToken } = status.route;

  if (!swap || !isChainflipTokenOrChain(srcToken) || !isChainflipTokenOrChain(destToken)) {
    return null;
  }

  const timeUntilExpiry =
    isRetrying &&
    status.integration === 'chainflip' &&
    status.integrationData?.depositChannel?.fillOrKillParams &&
    formatTimeUntilExpiry(
      status.integrationData.depositChannel.fillOrKillParams.retryDurationBlocks * 6 -
        (new Date().valueOf() - (getSwapScheduledAt(status) ?? 0)) / 1000,
      'less than a minute',
    );

  return (
    <div
      className="flex flex-col gap-y-2"
      data-testid={dcaInfo ? 'swapping-dca' : 'swapping-regular'}
    >
      <div className="flex items-center justify-between font-aeonikMedium">
        <div className="flex items-center gap-1 text-20 text-cf-white">
          <div className="flex items-center gap-x-2">
            {!dcaInfo && <LoadingSpinner className="!h-4 !w-4" />}
            <span>Swapping</span>
          </div>
          <span>{status.route.srcToken.symbol}</span>
          <ArrowIcon className="text-cf-light-2" />
          <span>{status.route.destToken.symbol}</span>
        </div>
        <div className="flex items-center gap-1 text-12 text-cf-light-2">
          {dcaInfo && (
            <span className="inline-flex items-center">
              Chunks &nbsp;
              <MotionNumber className="text-cf-light-3" value={dcaInfo.executedChunks} />/
              {dcaInfo.totalChunks}
            </span>
          )}
        </div>
      </div>
      {dcaInfo && (
        <DcaStatus info={dcaInfo} srcToken={srcToken} destToken={destToken} swap={swap} />
      )}
      {timeUntilExpiry && (
        <Callout type="info">
          <div className="text-14">
            Chainflip will attempt to meet your minimum payout for{' '}
            <span className="font-bold">{timeUntilExpiry}</span> before initiating a refund.
          </div>
        </Callout>
      )}
    </div>
  );
};
