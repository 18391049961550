import { useRef, useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { Switch2Icon } from '../icons/small';
import { TokenAmount, chainflipAssetMap } from '../utils';
import { type ChainflipAsset } from '../utils/chainflip';

export const Rate = ({
  depositAmount,
  sourceAsset,
  egressAmount,
  destinationAsset,
  className,
}: {
  depositAmount: string;
  sourceAsset: ChainflipAsset;
  egressAmount: string;
  destinationAsset: ChainflipAsset;
  className?: string;
}) => {
  const [showInverseRate, setShowInverseRate] = useState(false);
  const initialRender = useRef(true);

  const onSwitch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    initialRender.current = false;
    setShowInverseRate(!showInverseRate);
  };

  const switchAnimate = () => {
    if (initialRender.current) return 'none';
    if (!showInverseRate) return 'flash';
    return 'flash2';
  };

  const deposit = TokenAmount.fromAsset(depositAmount, sourceAsset);
  const egress = TokenAmount.fromAsset(egressAmount, destinationAsset);
  const rate = showInverseRate ? deposit.ratio(egress) : egress.ratio(deposit);

  return (
    <motion.button
      animate={switchAnimate()}
      variants={{
        none: { opacity: 1 },
        flash: { opacity: [0, 1] },
        flash2: { opacity: [0, 1] },
      }}
      transition={{ duration: 0.15 }}
      className={classNames(
        `flex items-center gap-x-1 text-cf-light-3 transition duration-150 ease-in hover:text-white`,
        className,
      )}
      onClick={onSwitch}
    >
      <span className="flex items-center">
        {rate.isNaN() ? '' : 1}{' '}
        {showInverseRate
          ? chainflipAssetMap[destinationAsset].symbol
          : chainflipAssetMap[sourceAsset].symbol}
      </span>
      <Switch2Icon className="cursor-pointer" />
      <span className="flex items-center">
        {rate.isNaN() ? '' : rate.toFormat(8)}{' '}
        {showInverseRate
          ? chainflipAssetMap[sourceAsset].symbol
          : chainflipAssetMap[destinationAsset].symbol}
      </span>
    </motion.button>
  );
};
