import React, { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import MotionNumber from 'motion-number';
import useTracking from '@/shared/hooks/useTracking';
import { BoltIcon } from '@/shared/icons/large';
import Lottie from '@/shared/utils/Lottie';
import receivingJson from '../../assets/lotties/receiving.json';
import useChainflipBlockConfirmations from '../../hooks/useChainflipBlockConfirmations';
import useViewTransitionStore from '../../hooks/useViewTransitionStore';
import type { StatusResponse } from '../../integrations';
import { SwapEvents } from '../../types/track';
import { defaultAnimationProps } from '../../utils/consts';
import {
  getDepositedAmount,
  getSwapFees,
  isBoostedChannel,
  isBoostPending,
  isBoostSkipped,
  isDepositFinished,
  isSwapBoosted,
} from '../../utils/sdk';

export const ReceivingStatus = ({ status }: { status: StatusResponse }) => {
  const { requiredBlockConfirmations } = useChainflipBlockConfirmations(
    status.route.srcToken.chain,
  );
  const { depositViewState: step, setDepositViewState: setStep } = useViewTransitionStore();
  const track = useTracking();
  const depositAmount = getDepositedAmount(status) ?? status.route.srcAmount;

  useEffect(() => {
    if (step === 'awaiting-deposit-confirmations' && isDepositFinished(status)) {
      setStep('transition-finished');
    }

    if (status.srcConfirmationCount && requiredBlockConfirmations && !isBoostPending(status)) {
      setStep('awaiting-deposit-confirmations');
    }

    if (step === 'awaiting-deposit-tx') {
      const boosted = isSwapBoosted(status);

      // If a smart contract swap or a normal deposit channel swap where the deposit already arrived
      if ((!status.depositAddress || !isBoostedChannel(status)) && isDepositFinished(status)) {
        setStep('transition-finished');
      } else if (boosted || isBoostSkipped(status)) {
        const { route } = status;
        setStep(boosted ? 'swap-boosted' : 'boost-skipped');
        track(boosted ? SwapEvents.DepositBoosted : SwapEvents.InsufficientBoostLiquidity, {
          props: {
            assetFrom: `${route.srcToken.chain.name}.${route.srcToken.symbol}`,
            assetTo: `${route.destToken.chain.name}.${route.destToken.symbol}`,
            assetFromAmount: route.srcAmount.toFixed(),
            quotedAmount: route.destAmount.toFixed(),
            ...(boosted && {
              boostFee: getSwapFees(status)?.find((fee) => fee.type === 'BOOST')?.amount,
            }),
          },
        });
        // Either move on to the next step or show the x/y confirmations screen after 4s
        setTimeout(() => {
          setStep(boosted ? 'transition-finished' : 'awaiting-deposit-confirmations');
        }, 4000);
      }
    }
  }, [status, requiredBlockConfirmations]);

  useEffect(() => {
    if (status.status === 'waiting_for_src_tx_confirmation') {
      track(SwapEvents.ReceivingDepositAmount, {
        props: {
          assetFrom: `${status.route.srcToken.chain.name}.${status.route.srcToken.symbol}`,
          assetFromAmount: status.route.srcAmount.toFixed(),
          assetTo: `${status.route.destToken.chain.name}.${status.route.destToken.symbol}`,
          quotedAmount: status.route.destAmount.toFixed(),
          destinationAddress: status.destAddress,
          integration: status.integration,
        },
      });
    }
  }, [status.status]);

  return (
    <AnimatePresence mode="wait">
      {step === 'awaiting-deposit-tx' && (
        <motion.div
          key="awaiting-deposit-tx"
          className="flex flex-row items-center gap-0.5"
          {...defaultAnimationProps}
        >
          <Lottie
            as="div"
            animationData={receivingJson}
            autoplay
            loop
            className="h-[24px] w-[24px]"
          />
          <span className="font-aeonikMedium text-24 text-cf-white">
            Receiving {depositAmount.toPreciseFixedDisplay().substring(0, 16)}{' '}
            {status.route.srcToken.symbol}
          </span>
        </motion.div>
      )}
      {step === 'awaiting-deposit-confirmations' && (
        <motion.div
          key="awaiting-deposit-confirmations"
          className="flex flex-row items-center gap-0.5"
          {...defaultAnimationProps}
        >
          <span className="inline-flex items-center font-aeonikMedium text-24 text-cf-white">
            <MotionNumber
              value={Math.min(
                status.srcConfirmationCount ?? 0,
                requiredBlockConfirmations ?? 0,
              ).toString()}
            />
            <span className="text-cf-light-2">/{requiredBlockConfirmations}</span>&nbsp; Block
            confirmations
          </span>
        </motion.div>
      )}
      {step === 'swap-boosted' && (
        <motion.div
          key="swap-boosted"
          className="flex flex-row items-center gap-0.5"
          data-testid="deposit-boosted"
          {...defaultAnimationProps}
        >
          <BoltIcon className="text-cf-pink-1" />
          <span className="font-aeonikMedium text-24 text-cf-white">Deposit boosted!</span>
        </motion.div>
      )}
      {step === 'boost-skipped' && (
        <motion.div
          key="boost-skipped"
          className="flex flex-row items-center gap-0.5"
          data-testid="no-boost-liquidity"
          {...defaultAnimationProps}
        >
          <BoltIcon className="text-cf-light-2" />
          <span className="font-aeonikMedium text-24 text-cf-white">
            Not enough Boost liquidity
          </span>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
