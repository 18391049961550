import type {} from '@redux-devtools/extension'; // required for devtools typing
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { validateChainAddress } from '@/shared/assets/chains/addressValidation';
import { type Token, type ChainflipToken } from '@/shared/assets/tokens';
import { chainflipAssetMap, type TokenAmount } from '@/shared/utils';
import type { RouteResponse } from '../integrations';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void
  ? I
  : never;

// eslint-disable-next-line @typescript-eslint/ban-types
type CombineIntersection<T> = {} & {
  [K in keyof T]: T[K];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
type StoreWithSetters<T extends Record<string, any>, Additional = {}> = CombineIntersection<
  UnionToIntersection<
    {
      [K in keyof T]: { [P in K]: T[K] } & {
        [P in `set${Capitalize<string & K>}`]: (value: T[K]) => void;
      };
    }[keyof T]
  > &
    Additional
>;

const initialData = {
  srcToken: chainflipAssetMap.Btc as Token | ChainflipToken,
  srcAmount: undefined as TokenAmount | undefined,
  destToken: chainflipAssetMap.Eth as Token | ChainflipToken,
  destinationAddress: '',
  selectedRoute: undefined as RouteResponse | undefined,
  refundAddress: '',
};

type Store = StoreWithSetters<
  typeof initialData,
  { reset: (srcToken: Token | ChainflipToken, destToken: Token | ChainflipToken) => void }
>;

export type AddressType = 'destination' | 'refund';

const useSwapRequestStore = create<Store>()(
  devtools(
    immer((set) => ({
      ...initialData,
      setSrcToken: (srcToken) => set({ srcToken }, false, 'setSrcToken'),
      setSrcAmount: (srcAmount) => set({ srcAmount }, false, 'setSrcAmount'),
      setDestToken: (destToken) => set({ destToken }, false, 'setDestToken'),
      setDestinationAddress: (destinationAddress) =>
        set({ destinationAddress }, false, 'setDestinationAddress'),
      setRefundAddress: (refundAddress) => set({ refundAddress }, false, 'setRefundAddress'),
      setSelectedRoute: (selectedRoute) => set({ selectedRoute }, false, 'setSelectedRoute'),
      reset: (srcToken: Token | ChainflipToken, destToken: Token | ChainflipToken) =>
        set({ ...initialData, srcToken, destToken }, false, 'reset'),
    })),
  ),
);

export const selectAddressIsValid = (type: AddressType) => (state: Store) => {
  const address = `${type}Address` as const;
  if (state[address] === '') return false;
  return validateChainAddress(state[address])[
    type === 'destination' ? state.destToken.chain.id : state.srcToken.chain.id
  ];
};

export const selectShowRouteList = (state: Store) =>
  Boolean(state.srcAmount && state.srcToken && state.destToken && state.srcAmount.gt(0));

export default useSwapRequestStore;
