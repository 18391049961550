import React, { Fragment, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { getChainflipId } from '@chainflip/sdk/swap';
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { type Token, type ChainflipToken } from '@/shared/assets/tokens';
import {
  Button,
  ChainTokenLogo,
  CompactSwapRoute,
  CopyButton,
  Link,
  Tooltip,
} from '@/shared/components';
import QuestionMarkTooltip from '@/shared/components/QuestionMarkTooltip';
import { Rate } from '@/shared/components/Rate';
import { BOOST_FEE, EGRESS_FEE, feeTypeMap, INGRESS_FEE, NETWORK_FEE } from '@/shared/constants';
import { usePriceOracle } from '@/shared/hooks/usePriceOracle';
import ArrowRightIcon from '@/shared/icons/ArrowRight';
import Chevron from '@/shared/icons/flip-ui-kit/large/Chevron';
import Bolt from '@/shared/icons/flip-ui-kit/small/Bolt';
import Gas from '@/shared/icons/flip-ui-kit/small/Gas';
import Route from '@/shared/icons/flip-ui-kit/small/Route';
import Send from '@/shared/icons/flip-ui-kit/small/Send';
import { ChevronIcon } from '@/shared/icons/large';
import { ArrowIcon, ClockIcon, FireIcon, TimeBoost } from '@/shared/icons/small';
import {
  abbreviate,
  formatToApproxTime,
  formatUsdValue,
  getChainflipToken,
  TokenAmount,
} from '@/shared/utils';
import { buildChainflipExplorerLink } from '@/shared/utils/chainflip';
import { ExternalLinkButton } from './EventLogV2';
import { StatusSection } from './StatusSection';
import useSettingsStore, { selectDepositMode } from '../../hooks/useSettingsStore';
import useSwapRequestStore from '../../hooks/useSwapRequestStore';
import { type RouteResponse, type StatusResponse } from '../../integrations';
import { calculateMinReceived } from '../../integrations/chainflip';
import { defaultAnimationProps } from '../../utils/consts';
import { getDepositedAmount, getRefundEgress, getSwapEgress, getSwapObject } from '../../utils/sdk';
import { AnimateHeightChange } from '../AnimateHeightChange';
import Card from '../Card';
import { UsdAmount } from '../UsdAmount';

const ExpandedSwapDetailValueRow = ({
  label,
  icon,
  children,
  tooltip,
}: {
  label: string;
  icon: React.ReactNode;
  children: React.ReactNode | string;
  tooltip?: React.ReactNode;
}) => (
  <div className="flex w-full items-center justify-between font-aeonikMedium text-13">
    <div className="flex flex-row items-center gap-x-1.5 text-cf-light-2">
      <div>{icon}</div>
      <span>{label}</span>
      <QuestionMarkTooltip content={tooltip} />
    </div>
    {children}
  </div>
);

const SwapDetailValueRow = ({
  label,
  children,
  tooltip,
  className,
}: {
  label: string;
  children: React.ReactNode | string;
  tooltip?: React.ReactNode;
  className?: string;
}) => (
  <div
    className={classNames(
      'flex w-full items-center justify-between font-aeonikMedium text-14',
      className,
    )}
  >
    <div className="flex flex-row items-center gap-x-1.5 text-cf-light-2">
      <span>{label}</span>
      <QuestionMarkTooltip content={tooltip} />
    </div>
    {children}
  </div>
);

const SwapDetailRowWithTokenAmount = ({
  amount,
  token,
  label,
  tooltip,
}: {
  label: string;
  tooltip?: string;
  amount: TokenAmount | undefined;
  token: Token;
}) => (
  <SwapDetailValueRow label={label} tooltip={tooltip} className="text-cf-light-3">
    <span className="flex items-center gap-x-1">
      <span>{amount?.toPreciseFixedDisplay()}</span>
      <ChainTokenLogo displayChainLogo={false} token={token} size="xsmall" />
      <span>{token.symbol}</span>
      <span className="text-cf-light-2">
        (<UsdAmount token={token} tokenAmount={amount} />)
      </span>
    </span>
  </SwapDetailValueRow>
);

const RouteSection = ({ route, disabled }: { route: RouteResponse; disabled: boolean }) => {
  const [showRouteDetails, setShowRouteDetails] = useState<boolean>(false);

  if (route.integration === 'chainflip') {
    const sourceAsset = route.srcToken.chainflipId;
    const destinationAsset = route.destToken.chainflipId;
    return (
      <CompactSwapRoute
        hideAmount
        flat
        routeInfo={{ sourceAsset, destinationAsset }}
        swapRequest={{
          sourceAsset,
          destinationAsset,
          executedSwaps: {
            totalCount: 1,
            aggregates: {
              sum: {
                swapInputAmount: route.srcAmount.toString(),
                swapInputValueUsd: '0',
                intermediateAmount: route.steps[1]?.srcAmount.toString() ?? '0',
                intermediateValueUsd: '0',
                swapOutputAmount: route.destAmount.toString(),
                swapOutputValueUsd: '0',
              },
            },
          },
        }}
        withTooltip
      />
    );
  }

  return (
    <>
      <div className="flex items-center justify-around">
        <div className="flex items-center justify-around gap-x-1">
          {route.steps.map((step, index) => (
            <Fragment key={step.protocolName + step.srcToken.symbol}>
              <ChainTokenLogo token={step.srcToken} size="small" displayChainLogo={index !== 1} />
              <ArrowRightIcon className="text-cf-light-1" width="16px" height="16px" />
              {index === route.steps.length - 1 && (
                <ChainTokenLogo token={step.destToken} size="small" />
              )}
            </Fragment>
          ))}
        </div>

        <button
          type="button"
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
            setShowRouteDetails(!showRouteDetails);
          }}
        >
          <Chevron
            flip={!showRouteDetails}
            className="cursor-pointer text-cf-light-3 duration-100"
          />
        </button>
      </div>

      <motion.div
        className="!mt-0 h-0 overflow-hidden font-aeonikMedium text-12 text-cf-light-2"
        animate={showRouteDetails ? 'open' : 'closed'}
        variants={{
          open: { height: 'auto', opacity: 1 },
          closed: { height: '0px', opacity: 0 },
        }}
        transition={{ duration: 0.15 }}
      >
        <div className="mt-2 flex flex-col gap-y-2">
          {route.steps.map((step) => (
            <div key={step.protocolName + step.srcToken.symbol}>
              <div className="ml-1 flex items-end gap-x-1">
                <div className="text-cf-light-2">
                  Swap{' '}
                  <span className="font-aeonikMono font-semibold">
                    {step.srcAmount.toPreciseFixedDisplay()}
                  </span>{' '}
                  {step.srcToken.symbol} to{' '}
                  <span className="font-aeonikMono font-semibold">
                    {step.destAmount.toPreciseFixedDisplay()}
                  </span>{' '}
                  {step.destToken.symbol} via {step.protocolName}
                </div>
              </div>
            </div>
          ))}
        </div>
      </motion.div>
    </>
  );
};

export const PlatformFees = ({
  fees,
}: {
  fees: {
    amount: TokenAmount;
    token: Token;
    name: string;
  }[];
}) =>
  fees.map((fee) => {
    let icon;
    switch (fee.name) {
      case INGRESS_FEE:
        icon = <ArrowIcon direction="down" />;
        break;
      case EGRESS_FEE:
        icon = <Send />;
        break;
      case NETWORK_FEE:
        icon = <FireIcon />;
        break;
      case BOOST_FEE:
        icon = <Bolt />;
        break;
      default:
        break;
    }
    return (
      <ExpandedSwapDetailValueRow
        key={fee.name}
        icon={icon}
        label={fee.name}
        tooltip={
          fee.name === NETWORK_FEE && (
            <div>
              Fixed fee taken in $USDC by the protocol to buy and burn $FLIP.{' '}
              <Link
                href="https://docs.chainflip.io/concepts/token-economics/incentive-design-emission-and-burning#the-network-fee"
                target="_blank"
                color="green"
              >
                Learn more
              </Link>
            </div>
          )
        }
      >
        <div>
          <span className="text-white">
            {fee.amount.toFixedDisplay()} {fee.token.symbol}{' '}
          </span>
          <span className="text-cf-light-2">
            (<UsdAmount token={fee.token} tokenAmount={fee.amount} />)
          </span>
        </div>
      </ExpandedSwapDetailValueRow>
    );
  });

const ExpandableDetails = ({ status }: { status: StatusResponse }) => {
  const { route } = status;
  const [expandPlatformFees, setExpandPlatformFees] = useState(false);
  const depositMode = useSettingsStore(selectDepositMode);

  const priceOracle = usePriceOracle();
  useEffect(() => priceOracle.watchTokens(route.platformFees.map(({ token }) => token)), [route]);

  let platformFees;
  let inputAmount;
  let outputAmount;

  if (
    status.integration === 'chainflip' &&
    status.integrationData &&
    'swap' in status.integrationData &&
    status.integrationData.swap?.dca
  ) {
    inputAmount = TokenAmount.fromAsset(
      status.integrationData.swap.swappedInputAmount,
      route.srcToken.chainflipId!,
    );
    outputAmount = TokenAmount.fromAsset(
      status.integrationData.swap.swappedOutputAmount,
      route.destToken.chainflipId!,
    );
    platformFees = (status.integrationData.fees ?? [])
      .filter((fee) => fee.type !== 'LIQUIDITY')
      .map((fee) => {
        const asset = getChainflipId(fee);
        const token = getChainflipToken(asset);

        return {
          amount: TokenAmount.fromAsset(fee.amount, asset),
          token,
          name: feeTypeMap[fee.type],
        };
      });
  } else {
    platformFees =
      route.integration === 'chainflip' && depositMode === 'contract'
        ? route.platformFees.filter((fee) => fee.name !== INGRESS_FEE)
        : route.platformFees;
    inputAmount = route.srcAmount;
    outputAmount = route.destAmount;
  }

  const totalPlatformFeeUsd = BigNumber.sum(
    ...platformFees.map((fee) =>
      fee.amount.mul(priceOracle.getPrice(fee.token) ?? 0).toBigNumber(),
    ),
  );

  return (
    <div className="w-full">
      <div className="flex items-center justify-between font-aeonikMedium text-13">
        <Rate
          depositAmount={inputAmount.toString()}
          sourceAsset={(route.srcToken as ChainflipToken).chainflipId}
          egressAmount={outputAmount.toString()}
          destinationAsset={(route.destToken as ChainflipToken).chainflipId}
        />
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            setExpandPlatformFees(!expandPlatformFees);
          }}
          className="group flex gap-x-3 transition ease-out hover:text-white"
        >
          <div className="flex items-center gap-x-2">
            <div className="flex items-center gap-x-1">
              <Gas />
              <span>{formatUsdValue(totalPlatformFeeUsd)}</span>
            </div>
            <div className="flex h-6 w-6 items-center justify-center rounded-md border border-cf-gray-5 bg-cf-gray-4 text-cf-light-3 transition ease-out group-hover:bg-cf-gray-5 group-hover:text-cf-white">
              <ChevronIcon flip={!expandPlatformFees} />
            </div>
          </div>
        </button>
      </div>

      <motion.div
        className="overflow-hidden"
        initial="closed"
        animate={expandPlatformFees ? 'open' : 'closed'}
        variants={{
          open: { height: 'auto', opacity: 1, marginTop: 'auto' },
          closed: { height: '0px', opacity: 0, marginTop: '0px' },
        }}
        transition={{ duration: 0.15 }}
      >
        <div className="flex w-full flex-col gap-y-2 pt-3">
          <PlatformFees fees={platformFees} />
          <div className="w-full border-b border-cf-gray-4" />
          <ExpandedSwapDetailValueRow label="Route" icon={<Route />}>
            <RouteSection route={route} disabled={false} />
          </ExpandedSwapDetailValueRow>
        </div>
      </motion.div>
    </div>
  );
};

export const SwapDetailsV2 = ({ status }: { status: StatusResponse }) => {
  const router = useRouter();
  const { route, destAddress } = status;
  const depositedAmount = getDepositedAmount(status);
  const swap = getSwapObject(status);
  const swapEgress = getSwapEgress(status);
  const swapEgressAmount =
    swapEgress && new TokenAmount(swapEgress.amount, status.route.destToken.decimals);
  const refundEgress = getRefundEgress(status);
  const refundEgressAmount =
    refundEgress && new TokenAmount(refundEgress.amount, status.route.srcToken.decimals);

  let refundAddress = useSwapRequestStore((state) => state.refundAddress);
  let minReceived;
  let payoutAmount;
  let isEstimate = false;

  if (status.integration === 'chainflip' && status.integrationData) {
    if (status.integrationData.depositChannel?.fillOrKillParams) {
      refundAddress = status.integrationData.depositChannel.fillOrKillParams.refundAddress;
      minReceived = calculateMinReceived(
        status.route,
        status.integrationData.depositChannel.fillOrKillParams.minPrice,
      );
    }
    if (swapEgressAmount) {
      payoutAmount = swapEgressAmount;
    } else {
      payoutAmount = status.route.destAmount;
      isEstimate = true;
    }
  }

  const isBoosted = route.integration === 'chainflip' && route.integrationData.isBoosted;
  const formattedDuration =
    route.durationSeconds > 0 ? formatToApproxTime(route.durationSeconds) : undefined;
  const formattedDefaultDuration =
    route.integration === 'chainflip' && route.integrationData.defaultDurationSeconds > 0
      ? formatToApproxTime(route.integrationData.defaultDurationSeconds)
      : undefined;

  const chunks =
    status.integration === 'chainflip'
      ? status.integrationData?.depositChannel?.dcaParams?.numberOfChunks ?? 1
      : 1;
  const swapLink =
    status.integration === 'chainflip'
      ? buildChainflipExplorerLink(status.integrationData?.swapId, 'swaps')
      : null;
  const timeTaken = status.duration ? formatToApproxTime(status.duration / 1000) : undefined;

  return (
    <Card className="flex w-full flex-col bg-cf-gray-3 text-12 shadow-grayPop1">
      <div className="flex items-center justify-between p-4">
        <span className="font-aeonikMedium text-16 text-cf-light-3 transition ease-out">
          Swap{' '}
          {status.integration === 'chainflip' && status.integrationData?.swapId
            ? `#${status.integrationData.swapId}`
            : 'details'}
        </span>
        {swapLink && <ExternalLinkButton href={swapLink} />}
      </div>
      <div className="w-full border-b border-cf-gray-4" />
      <div className="flex flex-col gap-y-3 p-4">
        <AnimateHeightChange>
          <StatusSection status={status} />
        </AnimateHeightChange>
        <Card className="flex flex-col gap-3 bg-cf-gray-3-5 p-3 shadow-grayPop2">
          {depositedAmount && (
            <SwapDetailRowWithTokenAmount
              label="You deposited"
              amount={depositedAmount}
              token={status.route.srcToken}
            />
          )}
          {status.status !== 'waiting_for_src_tx' && payoutAmount?.gt(0) && (
            <SwapDetailRowWithTokenAmount
              label={isEstimate ? 'Est. payout' : 'Payout'}
              amount={payoutAmount}
              token={status.route.destToken}
            />
          )}
          {refundEgress && (
            <SwapDetailRowWithTokenAmount
              label="Refund payout"
              amount={refundEgressAmount}
              token={status.route.srcToken}
            />
          )}
          {minReceived && !refundEgress && (
            <SwapDetailRowWithTokenAmount
              label="Min. payout"
              tooltip="Minimum amount received at the destination address based on slippage tolerance and estimated fees"
              amount={minReceived}
              token={status.route.destToken}
            />
          )}
          {chunks && chunks > 1 && (
            <SwapDetailValueRow
              label="Chunks"
              tooltip={
                <div>
                  Chunks are portions of your deposit amount that are split up and swapped over
                  time.{' '}
                  <Link
                    href="https://docs.chainflip.io/swapping/swapping-basics#dollar-cost-average-dca-improving-price"
                    target="_blank"
                    color="green"
                  >
                    Learn more
                  </Link>
                </div>
              }
            >
              {status.status === 'completed' || status.status === 'refunded' ? (
                <span>
                  {swap?.dca?.executedChunks ?? 0}/{chunks}
                </span>
              ) : (
                <span>{chunks}</span>
              )}
            </SwapDetailValueRow>
          )}
          {(timeTaken || formattedDuration) && (
            <SwapDetailValueRow label={timeTaken ? 'Completed in' : 'Est. time to complete'}>
              <div className="flex items-center gap-x-0.5">
                {isBoosted ? <TimeBoost className="text-cf-pink-1" /> : <ClockIcon />}
                <span className="font-aeonikMedium text-14">{timeTaken || formattedDuration}</span>
                {isBoosted && formattedDefaultDuration && (
                  <span className="pl-0.5 font-aeonikMedium text-12 text-cf-light-2 line-through">
                    {formattedDefaultDuration}
                  </span>
                )}
              </div>
            </SwapDetailValueRow>
          )}
          <div className="w-full border-b border-cf-gray-4" />
          <ExpandableDetails status={status} />
        </Card>
        <Card className="flex flex-col space-y-3 bg-cf-gray-3-5 p-3 shadow-grayPop2">
          {destAddress && (
            <SwapDetailValueRow label="Destination address">
              <div className="flex items-center gap-x-1 font-aeonikMedium text-cf-light-3">
                <Tooltip content={destAddress}>{abbreviate(destAddress)}</Tooltip>
                <CopyButton textToCopy={destAddress} />
              </div>
            </SwapDetailValueRow>
          )}
          {refundAddress && (
            <SwapDetailValueRow label="Refund address">
              <div className="flex items-center gap-x-1 font-aeonikMedium text-cf-light-3">
                <Tooltip content={refundAddress}>{abbreviate(refundAddress)}</Tooltip>
                <CopyButton textToCopy={refundAddress} />
              </div>
            </SwapDetailValueRow>
          )}
        </Card>
        {status.status === 'completed' && (
          <motion.div className="flex flex-col" {...defaultAnimationProps}>
            <Button onClick={() => router.push('/')}>Swap again</Button>
          </motion.div>
        )}
      </div>
    </Card>
  );
};
